/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// gatsby-browser.js and gatsby-ssr.js

// import "firebase/auth"
// import "firebase/firestore"
// import "firebase/functions"

import "./src/styles/global.css"